import React from "react";

import useTranslate from "@/hooks/useTranslate";
import DynamicIcon, { IconProps } from "./DynamicIcon";

interface CenteredMessageProps {
  title?: string;
  message?: string;
  icon?: IconProps["name"];
}

const CenteredMessage: React.FC<CenteredMessageProps> = ({
  title,
  message,
  icon,
}) => {
  const t = useTranslate();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-center">
      {icon && <DynamicIcon name={icon} />}
      <h3 className="mb-2 text-lg text-slate-400">
        {title ?? t("errors.something_went_wrong")}
      </h3>
      <h4 className="text-sm text-slate-500">
        {message ?? t("errors.battle_page")}
      </h4>
    </div>
  );
};

export default React.memo(CenteredMessage);
